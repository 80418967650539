const initialState = {
    geolocationLatitudeforLoyalty: 0,
    geolocationLongitudeforLoyalty: 0,
};

export const latLongForLoyaltyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_LAT_LONG_FOR_LOYALTY':
            return {
                geolocationLatitudeforLoyalty: action.payload.geolocationLatitudeforLoyalty,
                geolocationLongitudeforLoyalty: action.payload.geolocationLongitudeforLoyalty,
            };
        case 'RESET_LAT_LONG_FOR_LOYALTY':
            return initialState;
        default:
            return state;
    }
};