const initialState = {
    title: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    identityDocumentType: "",
    uniqueIdentificationNo: "",
    gender: "",
    birthdate: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    enrolledChannel: 2,
    enrolledLocation: "",
    bPPrintedBill: false,
    bPEBill: false,
    bPSMSBill: false,
    geolocationLatitude: 0,
    geolocationLongitude: 0,
    isAgreedForCondition: false,
    temperoryNexusCustomerID: 0,
    nexusRegistrationCustomerID: 0,
    initialDataLoading: false
};

export const loyaltyRegistrationDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_LOYALTY_REGISTRATION_DETAIL':
            return {
                title: action.payload.title,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                mobileNumber: action.payload.mobileNumber,
                identityDocumentType: action.payload.identityDocumentType,
                uniqueIdentificationNo: action.payload.uniqueIdentificationNo,
                gender: action.payload.gender,
                birthdate: action.payload.dob,
                email: action.payload.email,
                addressLine1: action.payload.addressLine1,
                addressLine2: "",
                city: action.payload.city,
                enrolledChannel: 1,
                enrolledLocation: action.payload.enrolledLocation,
                bPPrintedBill: action.payload.billTypeID == 1 ? true : false,
                bPEBill: action.payload.billTypeID == 2 ? true : false,
                bPSMSBill: action.payload.billTypeID == 3 ? true : false,
                geolocationLatitude: action.payload.geolocationLatitude,
                geolocationLongitude: action.payload.geolocationLongitude,
                isAgreedForCondition: action.payload.isAgreedForCondition,
                temperoryNexusCustomerID: action.payload.temperoryNexusCustomerID,
                nexusRegistrationCustomerID: action.payload.nexusRegistrationCustomerID,
                initialDataLoading: action.payload.initialDataLoading
            };
        case 'RESET_LOYALTY_REGISTRATION_DETAIL':
            return initialState;
        default:
            return state;
    }
};